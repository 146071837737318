/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useRouter } from 'next/router';
// import { withApollo } from '../../../config/apollo';
import { getCookie } from 'cookies-next';
import Skeleton from '@app/components/atoms/Skeleton';
import HeaderWithSidebar from '@app/components/templates/HeaderWithSidebar';
import TwoColumns from '../../../components/templates/TwoColumns';
import SignInForm from '../../../components/organisms/auth/SignInForm';
// import { redirectToRoute } from '../../../helpers';
import { useTranslation } from '../../../config/i18n';
import { goToDashboard, isUser, redirectToRoute } from '../../../helpers';
import { OK } from '../../../constants/guard-code';

const Login = () => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { query = {} } = router;
  const [isAuth, setIsAuth] = useState(false)
  const next = query.next || null;

  useEffect(() => {
    const accessToken = getCookie('token');
    if (!accessToken || accessToken === undefined || accessToken === null) {
      setIsAuth(false)
      !next && redirectToRoute({}, 'login');
    }else {
      setIsAuth(true)
      if (next) {
        router.push(next);
      }else {
        redirectToRoute({}, 'opportunity_search');
      }
    }
  }, []);

  return (
    <>
      { isAuth && !next ?
        <HeaderWithSidebar contentPadding={0} >
          <Skeleton height="450px" />
        </HeaderWithSidebar>
      :
        <TwoColumns title={t('login')} img="/images/login.jpg">
          <Head>
            <title>{t('login')}</title>
          </Head>
          <Container>
            <Typography variant="h4" component="h4" gutterBottom>
            {t('login')}
            </Typography>
            <Grid item xs={12}>
              <SignInForm />
            </Grid>
          </Container>
        </TwoColumns>
      }
    </>
  )
};


Login.guard = async (ctx, user) => {
  if(isUser(user)) {
    goToDashboard(ctx, user);
  }
  return OK;
}


export async function getServerSideProps() {
  return {
    props: {
      namespacesRequired: ['common', 'footer'],
    }
  }
}

export default Login;
