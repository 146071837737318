import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import {  Form } from 'formik';


export const SignInContainer = styled(Grid)`
  && {
    margin-top: 2rem;
    padding-bottom: 1rem;
    text-align: center;
   

  }
`;

export const ButtonContainer = styled(Grid)`
  && {
    margin-top: 2rem;
  }
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;
