import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as MuiCheckbox } from '@mui/material';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormControlLabel } from './style';

const Checkbox = ({
  label,
  color,
  labelPlacement,
  checked,
  onChange,
  value,
  disabled,
  ...props
}) => {
  if (!label) {
    return (
      <MuiCheckbox
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        color={color}
        value={value}
        {...props}
      />
    );
  }

  return (
    <FormControlLabel
      labelPlacement={labelPlacement}
      disabled={disabled}
      control={
        <MuiCheckbox onChange={onChange} checked={checked} color={color} value={value} {...props} />
      }
      label={label}
    />
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  color: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  label: undefined,
  labelPlacement: 'end',
  color: 'primary',
  checked: false,
  value: undefined,
  disabled: false,
  onChange: () => {},
};

export default Checkbox;
